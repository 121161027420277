import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
// import PropTypes from 'prop-types'

import { LoadingOutlined } from '@ant-design/icons'
import { Collapse, Table, Switch, message, Spin } from 'antd'
import PropTypes from 'prop-types'

import axiosClient from 'services/api'

import UnitAccess from './UnitAccess'

const { Panel } = Collapse

const UnitList = ({ units, user, loadingTable, disabled = false }) => {
  const [list, setList] = useState([])
  const intl = useIntl()

  useEffect(() => {
    setList(units)
  }, [units])

  const handleUnitChange = async (value, unit) => {
    try {
      let isLimitReached = false

      if (value === true) {
        await axiosClient
          .post(`CAC/entities/ELEMENTO`, {
            id_usuario: user.id_usuario,
            id_elemento: unit.id_elemento,
          })
          .then(resp => resp.data)
          .then(async response => {
            if (response.status === 406) {
              message.warning(
                intl.formatMessage({
                  id: 'global.warning.messages.registerUserLimit',
                }),
                7
              )
              isLimitReached = true
            }
          })
      } else {
        await axiosClient.put(`CAC/queries/ELEMENTO`, {
          id_usuario: user.id_usuario,
          id_elemento: unit.id_elemento,
        })
      }

      if (!isLimitReached) {
        setList([
          ...list.filter(l => l.id_elemento !== unit.id_elemento),
          {
            ...unit,
            selected: value,
          },
        ])
      }
    } catch (error) {
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    }
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />
  return user.usu_tipo === '1' ? (
    <Spin spinning={loadingTable} size="middle" indicator={antIcon}>
      <Table
        size="small"
        dataSource={list
          .map(row => ({
            id_elemento: row.id_elemento,
            plt_codigo: row.plt_codigo,
            ele_descri: row.ele_descri,
            selected: row.selected,
          }))
          .sort((a, b) => a.id_elemento - b.id_elemento)}
        columns={[
          {
            title: intl.formatMessage({ id: 'global.labels.unit' }),
            dataIndex: 'id_elemento',
            key: 'id_elemento',
            render: (text, row) => `${row.plt_codigo} - ${row.ele_descri}`,
          },
          {
            title: intl.formatMessage({ id: 'global.button.edit' }),
            dataIndex: 'selected',
            key: 'selected',
            render: (text, row) => (
              <Switch
                checked={row.selected}
                onChange={value => {
                  handleUnitChange(value, row)
                }}
                disabled={disabled}
              />
            ),
          },
        ]}
      />
    </Spin>
  ) : (
    <Collapse accordion>
      {list
        .sort((a, b) => a.key - b.key)
        .map(unit => (
          <Panel
            key={unit.key}
            header={`${unit.plt_codigo} - ${unit.ele_descri}`}
            extra={<Switch disabled checked={unit.selected} />}
          >
            <UnitAccess unit={unit} user={user} setList={setList} />
          </Panel>
        ))}
    </Collapse>
  )
}

UnitList.propTypes = {
  units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id_usuario: PropTypes.number,
    usu_tipo: PropTypes.string,
  }).isRequired,
  loadingTable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

UnitList.defaultProps = {
  disabled: false,
}

export default UnitList
