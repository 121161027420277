/* eslint-disable react/prop-types */
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useIntl } from 'react-intl'

import { Alert } from 'antd'

import useLogger from 'hooks/useElasticLogger'

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const intl = useIntl()
  const logger = useLogger()

  logger.error('component', String(error))

  return (
    <>
      <Alert
        message={intl.formatMessage({
          id: 'global.error.messages.defaultError',
        })}
        type="error"
        showIcon
      />
      <button onClick={resetErrorBoundary} type="button">
        Tentar novamente
      </button>
    </>
  )
}

export default ErrorFallback
