import { useSelector } from 'react-redux'

/**
 * A function that checks if a user has access to perform a certain action and checks if a user has a specific role.
 *
 * @param {string} id - The identifier for the access control.
 * @return {{can: (action: string) => boolean, is: (role: string) => boolean}} An object with two functions: "can" and "is".
 */
function useAccessControl(id) {
  const accessControl = useSelector(state => state.accessControl?.data) || {}
  const user = useSelector(state => state.user?.profile) || {}

  function can(action) {
    if (user.hasAccess) {
      return true
    }

    // console.info(`accessControl.${id}.${action}`)
    try {
      return Boolean(accessControl[id][action])
    } catch (error) {
      return false
    }
  }

  // This function checks if a user has a specific role.
  function is(role) {
    // Determine the current role of the user based on their access level.
    const currentUserRole = user.hasAccess ? 'admin' : 'user'

    // Check if the current role matches the specified role.
    return role === currentUserRole
  }

  return { can, is }
}

export default useAccessControl
