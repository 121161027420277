import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Upload, message, Avatar } from 'antd'
import PropTypes from 'prop-types'

import axiosClient from 'services/api'

import { Container } from './styles'

function beforeUpload(file, intl) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error(intl.formatMessage({ id: 'accessControl.users.pngJpg' }))
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error(intl.formatMessage({ id: 'accessControl.users.lessTwoMb' }))
  }
  return isJpgOrPng && isLt2M
}

function getServerData() {
  return {
    dir: `${axiosClient.defaults.baseURL}INT/actions/getFile/`,
    host: `${axiosClient.defaults.baseURL}INT/actions/uploadFile`,
  }
}

function UploadAvatar({ value, onChange, disabled = false }) {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const intl = useIntl()

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      const newImageUrl = `${getServerData().dir}${
        Array.isArray(info.file.response) && info.file.response[0]
      }`

      setImageUrl(newImageUrl)
      onChange(newImageUrl)
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="ant-upload-text">
        {intl.formatMessage({ id: 'accessControl.users.upload' })}
      </div>
    </div>
  )

  useEffect(() => {
    setImageUrl(value)
  }, [value])

  return (
    <Container>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={getServerData().host}
        beforeUpload={file => beforeUpload(file, intl)}
        onChange={handleChange}
        disabled={disabled}
      >
        {imageUrl ? (
          <Avatar size={128} src={imageUrl} alt="avatar" />
        ) : (
          uploadButton
        )}
      </Upload>
    </Container>
  )
}

UploadAvatar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

UploadAvatar.defaultProps = {
  value: null,
  onChange: () => {},
  disabled: false,
}

export default UploadAvatar
